import { Component, Vue } from "vue-property-decorator";
import { CREATE, EDIT, COPY } from "@/constants/FormType";

@Component
export default class ViewSwitchMixin extends Vue {
  public visibleView = false;
  public visibleForm = false;
  public visibleFormTheoryLesson = false;
  public formType = CREATE;
  public selectedRow: any = null;
  public visibleFormTheoryExam = false;
  public visibleDrivingSchoolAppointment = false;

  public closeView(): void {
    this.visibleView = false;
  }

  public closeForm(): void {
    this.visibleForm = false;
    this.visibleFormTheoryLesson = false;
    this.visibleFormTheoryExam = false;
    this.visibleDrivingSchoolAppointment = false;
  }

  public get fullWith(): boolean {
    return !this.visibleForm && !this.visibleView && !this.visibleFormTheoryLesson;
  }

  public openForm(): void {
    this.visibleView = false;
    this.visibleFormTheoryLesson = false;
    this.visibleFormTheoryExam = false;
    this.visibleForm = true;
    this.visibleDrivingSchoolAppointment = false;
  }

  public openFormTheoryLesson(): void {
    this.visibleView = false;
    this.visibleForm = false;
    this.visibleFormTheoryLesson = true;
    this.visibleFormTheoryExam = false;
    this.visibleDrivingSchoolAppointment = false;
  }

  public openFormTheoryExam(): void {
    this.visibleView = false;
    this.visibleForm = false;
    this.visibleFormTheoryExam = true;
    this.visibleFormTheoryLesson = false;
    this.visibleDrivingSchoolAppointment = false;
  }

  public openFormDrivingSchoolAppointment(): void {
    this.visibleView = false;
    this.visibleForm = false;
    this.visibleFormTheoryExam = false;
    this.visibleFormTheoryLesson = false;
    this.visibleDrivingSchoolAppointment = true;
  }

  public openView(): void {
    this.visibleForm = false;
    this.visibleFormTheoryLesson = false;
    this.visibleFormTheoryExam = false;
    this.visibleView = true;
    this.visibleDrivingSchoolAppointment = false;
  }

  public toggleView(): void {
    this.visibleFormTheoryLesson = !this.visibleFormTheoryLesson;
    this.visibleForm = !this.visibleForm;
    this.visibleView = !this.visibleView;
  }

  public onCreate(): void {
    this.formType = CREATE;
    this.openForm();
  }

  public onEdit(): void {
    if (!this.selectedRow) return;

    this.formType = EDIT;
    this.openForm();
  }

  public onCopy(): void {
    if (!this.selectedRow) return;

    this.formType = COPY;
    this.openForm();
  }

  public typeCreate(): boolean {
    return this.formType === CREATE;
  }

  public typeEdit(): boolean {
    return this.formType === EDIT;
  }

  public typeCopy(): boolean {
    return this.formType === COPY;
  }
}
