








import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import { getFile } from "@/utils/File";

@Component({
  components: {
    FscSimpleCard,
    AbortButton,
  },
})
export default class TheoryExamProtocolPreview extends Vue {
  public name = "TheoryExamProtocolPreview";

  private isLoading = false;

  @Prop()
  public studentDocumentId!: any;

  private source = "";

  public mounted(): void {
    this.getStudentDocument();
  }

  private getStudentDocument() {
    this.isLoading = true;
    if (this.studentDocumentId) {
      getFile(
        {
          url: `/student-documents/${this.studentDocumentId}`,
        },
        false
      )
        .then((blob) => {
          this.source = blob;
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }

  @Watch("studentDocumentId")
  private onItemChange(): void {
    this.getStudentDocument();
  }
}
