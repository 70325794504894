





































import { Component, Prop, Inject, ProvideReactive, InjectReactive } from "vue-property-decorator";
import PracticalExamParticipantsList from "@/views/Exam/PracticalExam/Participants/Edit/PracticalExamParticipantsList.vue";
import PracticalExamStudentsSelect from "@/views/Exam/PracticalExam/Participants/Add/PracticalExamStudentsSelect.vue";
import FuncMixins from "@/mixins/FuncMixins";
import { mixins } from "vue-class-component";
import TemplateEmailForm from "@/components/TemplateEmailForm.vue";
import InstructorRequestMixin from "@/mixins/Request/InstructorRequestMixin";

@Component({
  components: { TemplateEmailForm, PracticalExamStudentsSelect, PracticalExamParticipantsList },
})
export default class PracticalExamParticipants extends mixins(FuncMixins, InstructorRequestMixin) {
  public name = "PracticalExamParticipants";

  @Prop()
  private examId!: any;

  @Prop()
  protected exam!: any;

  @Prop()
  protected form!: any;

  @Prop({ default: false })
  protected archived!: any;

  @ProvideReactive("filterPracticalExam")
  public filterPracticalExam = "";

  @Inject("openParticipants")
  protected openParticipants: any;

  @Inject("openStudentsSelect")
  protected openStudentsSelect: any;

  @InjectReactive("practicalExamLoading")
  protected practicalExamLoading!: boolean;

  @InjectReactive("createDeleteOrUpdateLoadingPracticalExam")
  private createDeleteOrUpdateLoadingPracticalExam!: boolean;

  public reduceColumns = false;
  public allStudents: Array<any> = [];

  protected get isParticipants(): boolean {
    return this.form == "participants";
  }

  protected onCloseStudentSelect(): void {
    this.openParticipants();
  }

  protected onFilterPracticalExam(value: string) {
    this.debouncer(() => {
      this.filterPracticalExam = value;
    });
  }

  public onReduceColumns(flag: boolean) {
    this.reduceColumns = flag;
  }
  public onSetRecipients(recipients: any) {
    const { allStudents } = recipients;
    this.allStudents = allStudents;
  }
  public get recipients() {
    return this.allStudents || [];
  }

  public mounted() {
    this.filterInstructor({ archived: false });
  }

  private get isLoading() {
    return this.practicalExamLoading || this.createDeleteOrUpdateLoadingPracticalExam;
  }
}
