









































































import { Component, Provide, ProvideReactive } from "vue-property-decorator";
import FscPageHeader from "@/components/FscPageHeader.vue";
import PracticalExamList from "@/views/Exam/PracticalExam/PracticalExamList.vue";
import Actions from "@/components/Actions.vue";
import CreateButton from "@/components/Button/CreateButton.vue";
import CopyButton from "@/components/Button/CopyButton.vue";
import VerticalDivider from "@/components/VerticalDivider.vue";
import EditButton from "@/components/Button/EditButton.vue";
import RemoveButton from "@/components/Button/RemoveButton.vue";
import ArchiveButton from "@/components/Button/ArchiveButton.vue";
import FilterButton from "@/components/Button/FilterButton.vue";
import { IPracticalExam } from "@/interfaces/Exam/IPracticalExam";
import ArchiveModal from "@/components/Modal/ArchiveModal.vue";
import ModalMixin from "@/mixins/ModalMixin";
import { mixins } from "vue-class-component";
import PracticalExamInfo from "@/views/Exam/PracticalExam/Exam/PracticalExamInfo.vue";
import PracticalExamParticipants from "@/views/Exam/PracticalExam/Participants/PracticalExamParticipants.vue";
import moment from "moment";
import ExamMixin from "@/mixins/ExamMixin";
import FilterAndSearch from "@/components/FilterAndSearch.vue";
import FuncMixins from "@/mixins/FuncMixins";
import DeleteModal from "@/components/Modal/DeleteModal.vue";
import FscMultiselect from "@/components/FscMultiselect.vue";
import PracticalExamRequestMixin from "@/mixins/Request/PracticalExamRequestMixin";
import { ExamModeEnum } from "@/enums/ExamModeEnum";
import StudentEducationRequestMixin from "@/mixins/Request/StudentEducationRequestMixin";

@Component({
  components: {
    FilterAndSearch,
    PracticalExamParticipants,
    PracticalExamInfo,
    ArchiveModal,
    FilterButton,
    ArchiveButton,
    RemoveButton,
    EditButton,
    VerticalDivider,
    CopyButton,
    CreateButton,
    Actions,
    PracticalExamList,
    FscPageHeader,
    DeleteModal,
    FscMultiselect,
  },
})
export default class PracticalExam extends mixins(ModalMixin, ExamMixin, FuncMixins, PracticalExamRequestMixin, StudentEducationRequestMixin) {
  public name = "PracticalExam";

  private selectedExam: IPracticalExam | null = null;

  private examMode = ExamModeEnum.add;
  private showList = true;

  // participants/studentsSelect
  private form = "participants";

  public archived = false;

  public deletePracticalExamModalId = "delete-practical-exam-modal-id";

  public filterPracticalExam = "";

  // get practical exam
  @Provide("fetchPracticalExam")
  private async fetchPracticalExamFn(examId: number) {
    return await this.fetchPracticalExam(examId);
  }

  @ProvideReactive("practicalExamLoading")
  private get practicalExamLoadingFn() {
    return this.practicalExamLoading;
  }

  // get all practical exams
  @Provide("loadExams")
  private loadExams(): void {
    const practicalExamList: any = this.$refs.practicalExamList;
    if (practicalExamList as PracticalExamList) {
      practicalExamList.currentPage = 1;
      practicalExamList.loadExams();
    }
  }

  // portions
  @Provide("getPracticalExamPortions")
  private async getPracticalExamPortions(licenseClass: string) {
    return await this.getPortionsPracticalExam(licenseClass);
  }

  @ProvideReactive("practicalExamPortions")
  private get practicalExamPortions() {
    return this.portionsPracticalExam;
  }

  @ProvideReactive("portionsPracticalExamLoading")
  private get portionsPracticalExamLoadingFn() {
    return this.portionsPracticalExamLoading;
  }

  // educations
  @Provide("getStudentEducations")
  private async getStudentEducations(studentId: number) {
    return await this.fetchStudentEducationsArchived(studentId, false);
  }

  @ProvideReactive("studentEducations")
  private get studentEducationsExcludeArchived() {
    return this.studentEducations;
  }

  @ProvideReactive("studentEducationsLoading")
  private get studentEducationsLoadingFn() {
    return this.studentEducationsLoading;
  }

  // practical exam statuses
  @Provide("updatePracticalExamStatus")
  private async updatePracticalExamStatusFn(practicalExamStatuses: Array<any>) {
    return await this.updatePracticalExamStatus(practicalExamStatuses);
  }

  @ProvideReactive("updatePracticalExamStatusSuccess")
  private get updatePracticalExamStatusSuccessFn() {
    return this.updatePracticalExamStatusSuccess;
  }

  @ProvideReactive("updatePracticalExamStatusLoading")
  private get updatePracticalExamStatusLoadingFn() {
    return this.updatePracticalExamStatusLoading;
  }

  // participants
  @Provide("updateParticipantsPracticalExam")
  private async updateParticipantsPracticalExamFn(data: any) {
    return await this.updateParticipantsPracticalExam(data);
  }

  @ProvideReactive("updateParticipantsPracticalExamSuccess")
  private get updateParticipantsPracticalExamSuccessFn() {
    return this.updateParticipantsPracticalExamSuccess;
  }

  @ProvideReactive("updateParticipantsPracticalExamLoading")
  private get updateParticipantsPracticalExamLoadingFn() {
    return this.updateParticipantsPracticalExamLoading;
  }

  @Provide("removeParticipantPracticalExam")
  private async removeParticipantPracticalExamFn(examId: number) {
    return await this.removeParticipantPracticalExam(examId);
  }

  @ProvideReactive("removeParticipantPracticalSuccess")
  private get removeParticipantPracticalSuccessFn() {
    return this.removeParticipantPracticalSuccess;
  }

  @ProvideReactive("removeParticipantPracticalExamLoading")
  private get removeParticipantPracticalExamLoadingFn() {
    return this.removeParticipantPracticalExamLoading;
  }

  @ProvideReactive("archivePracticalExamLoading")
  private get archivePracticalExamLoadingFn() {
    return this.archivePracticalExamLoading;
  }

  //book
  @Provide("bookPracticalExam")
  private async bookPracticalExamFn(examId: number) {
    return await this.bookPracticalExam(examId);
  }

  @ProvideReactive("bookPracticalExamSuccess")
  private get bookPracticalExamSuccessFn() {
    return this.bookPracticalExamSuccess;
  }

  @ProvideReactive("bookPracticalExamLoading")
  private get bookPracticalExamLoadingFn() {
    return this.bookPracticalExamLoading;
  }

  //upload protocol
  @Provide("uploadProtocolPracticalExam")
  private async uploadProtocolPracticalExamFn(data: FormData) {
    return await this.uploadProtocolPracticalExam(data);
  }

  @ProvideReactive("uploadProtocolPracticalExamSuccess")
  private get uploadProtocolPracticalExamSuccessFn() {
    return this.uploadProtocolPracticalExamSuccess;
  }

  @ProvideReactive("uploadProtocolPracticalExamLoading")
  private get uploadProtocolPracticalExamLoadingFn() {
    return this.uploadProtocolPracticalExamLoading;
  }

  // Education Status Info Practical Exam
  @Provide("educationStatusInfoPracticalExam")
  private async educationStatusInfoPracticalExamFn(id: number) {
    return await this.educationStatusInfoPracticalExam(id);
  }

  @ProvideReactive("educationStatusInfoPracticalExamSuccess")
  private get educationStatusInfoPracticalExamSuccessFn() {
    return this.educationStatusInfoPracticalExamSuccess;
  }

  @ProvideReactive("educationStatusInfoPracticalExamData")
  private get educationStatusInfoPracticalExamDataFn() {
    return this.educationStatusInfoPracticalExamData;
  }

  // practical exams
  @Provide("getAllPracticalExam")
  private async getAllPracticalExamFn(filter: any) {
    return await this.getAllPracticalExam(filter);
  }

  @ProvideReactive("allPracticalExamsLoading")
  private get allPracticalExamsLoadingFn() {
    return this.allPracticalExamsLoading;
  }

  @ProvideReactive("allPracticalExams")
  private get allPracticalExamsFn() {
    return this.allPracticalExams;
  }

  @ProvideReactive("allPracticalExamsRows")
  private get allPracticalExamsRowsFn() {
    return this.allPracticalExamsRows;
  }

  // ready practical exam
  @Provide("readyPracticalExam")
  private async readyPracticalExamFn(practicalExamId: number) {
    return await this.readyPracticalExam(practicalExamId);
  }

  @ProvideReactive("readyPracticalExamLoading")
  private get readyPracticalExamLoadingFn() {
    return this.readyPracticalExamLoading;
  }

  @ProvideReactive("deletePracticalExamLoading")
  private get deletePracticalExamLoadingFn() {
    return this.deletePracticalExamLoading;
  }

  private async createPracticalExamProxy(exam: any) {
    await this.createPracticalExam(exam);

    if (this.createPracticalSuccess) {
      this.showList = true;
      this.loadExams();
    }
  }

  private async updatePracticalExamProxy(exam: any) {
    await this.updatePracticalExam(exam.id, exam);

    if (this.updatePracticalSuccess) {
      this.showList = true;
      this.loadExams();
      this.fetchPracticalExam(exam.id);
    }
  }

  @Provide("createPracticalExam")
  protected createPracticalExamFn(exam: any): void {
    this.createPracticalExamProxy(exam);
  }

  @ProvideReactive("createPracticalExamLoading")
  protected get createPracticalExamLoadingFn() {
    return this.createPracticalExamLoading;
  }

  @ProvideReactive("updatePracticalExamLoading")
  protected get updatePracticalExamLoadingFn() {
    return this.updatePracticalExamLoading;
  }

  @Provide("updatePracticalExam")
  protected updatePracticalExamFn(exam: any): void {
    this.updatePracticalExamProxy(exam);
  }

  @Provide("openParticipants")
  protected openParticipants(): void {
    this.form = "participants";
  }

  @Provide("openStudentsSelect")
  protected openStudentsSelect(): void {
    this.form = "studentsSelect";
  }

  @ProvideReactive("createDeleteOrUpdateLoadingPracticalExam")
  private get createDeleteOrUpdateLoading() {
    return this.createPracticalExamLoading || this.updatePracticalExamLoading || this.deletePracticalExamLoading;
  }

  private async archivePracticalExamProxy(opt: any) {
    await this.archivePracticalExam(opt);
    if (this.archivePracticalSuccess) {
      this.selectedExam = null;
      this.onCloseTheoryExamParticipants();
      this.loadExams();
    }
  }

  private get exam() {
    return this.practicalExam;
  }

  public onSearchPracticalExam(value: string): void {
    this.debouncer(() => {
      this.filterPracticalExam = value;
    });
  }

  private addExam(): void {
    this.showList = false;
    this.examMode = ExamModeEnum.add;
  }

  private editExam(): void {
    this.showList = false;
    this.examMode = ExamModeEnum.edit;
  }

  private onExamClick(exam: IPracticalExam, parentSelected: boolean) {
    this.selectedExam = Object.assign(exam, {});
    this.openParticipants();
    if (this.selectedExam && this.selectedExam.id) {
      this.examMode = ExamModeEnum.edit;
      this.fetchPracticalExam(this.selectedExam.id);
    }
  }

  private onCloseTheoryExamParticipants(): void {
    this.selectedExam = null;
  }

  private showArchiveModal(): void {
    if (!this.selectedExam) {
      return;
    }
    this.showModal("practical-exam-archive-modal");
  }

  private get frontendDate(): string {
    if (this.selectedExam) {
      return moment(this.selectedExam.date).format("DD.MM.YYYY");
    } else {
      return "";
    }
  }

  private get frontendTime(): string {
    if (this.selectedExam) {
      return moment(this.selectedExam.time, [moment.ISO_8601, "HH:mm"]).format("HH:mm");
    } else {
      return "";
    }
  }

  private async onDeletePracticalExam(): Promise<void> {
    const practicalExamId = this.selectedExam?.id;
    if (practicalExamId) {
      await this.deletePracticalExam(practicalExamId);
      if (this.deletePracticalSuccess) {
        this.onCloseTheoryExamParticipants();
        this.loadExams();
      }
    }
  }

  private onDeletePracticalExamModal(): void {
    if (this.hasNotPermissionWrite) return;
    if (!this.selectedExam?.id) return;
    this.$bvModal.show(this.deletePracticalExamModalId);
  }

  private get archivedOptions(): Array<any> {
    return [
      {
        text: this.$tc("general.active", 2),
        value: false,
      },
      {
        text: this.$tc("general.archived", 2),
        value: true,
      },
    ];
  }
}
