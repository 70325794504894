




import { Component, Vue, Prop } from "vue-property-decorator";
import ActionButton from "@/components/Button/ActionButton.vue";
@Component({
  components: { ActionButton },
})
export default class CopyButton extends Vue {
  public name = "CopyButton";

  @Prop({ default: () => "Kopieren", type: String })
  public label!: string;
}
