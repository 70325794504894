import { Component, Vue } from "vue-property-decorator";
import { AxiosError, AxiosResponse } from "axios";
import axios from "@/utils/axios";
import { ulrStringify } from "@/utils/UrlUtils";
import { ITuv } from "@/interfaces/ITuv";

const URL = "tuv-services";

@Component
export default class TuvRequestMixin extends Vue {
  public tuvServices: Array<ITuv> = [];
  public tuvServicesLoading = false;
  public tuvServicesSuccess = false;
  public tuvServicesError: Record<any, any> | null = null;

  public async findAllTuvServices(params: any = {}): Promise<void> {
    this.tuvServicesLoading = true;
    this.tuvServicesSuccess = false;
    this.tuvServicesError = null;

    const args = ulrStringify(params);

    return await axios
      .get(`${URL}${args}`)
      .then((response: AxiosResponse) => {
        this.tuvServices = response.data;
        this.tuvServicesSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.tuvServicesError = error.response?.data;
      })
      .finally(() => {
        this.tuvServicesLoading = false;
      });
  }

  public durationSuccess = false;
  public durationLoading = false;
  public durationError = null;
  public durationTime = null;

  public async studentEducationDurationTime(educationId: number) {
    this.durationSuccess = false;
    this.durationLoading = true;
    this.durationError = null;
    return await axios
      .get(`/exam-slots-config/for-education/${educationId}`)
      .then((response: AxiosResponse) => {
        this.durationTime = response.data;
        this.durationSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.durationError = error.response?.data;
      })
      .finally(() => {
        this.durationLoading = false;
      });
  }

  public async studentEducationDurationTimePortions(educationId: number, portionId: number) {
    this.durationSuccess = false;
    this.durationLoading = true;
    this.durationError = null;
    return await axios
      .get(`/exam-slots-config/for-education/${educationId}/portion/${portionId}`)
      .then((response: AxiosResponse) => {
        this.durationTime = response.data;
        this.durationSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.durationError = error.response?.data;
      })
      .finally(() => {
        this.durationLoading = false;
      });
  }
}
