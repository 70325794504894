









































import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import FscModal from "@/components/Modal/FscModal.vue";
import { namespace } from "vuex-class";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import { RegionSpecificDocumentCategoriesEnum } from "@/enums/RegionSpecificDocumentCategoriesEnum";
import UserService from "@/services/UserService";
import { getFile } from "@/utils/File";
import { IRegionSpecificDocument } from "@/interfaces/Exam/IRegionSpecificDocument";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import MailButton from "@/components/Button/MailButton.vue";
import PdfView from "@/views/AutomaticPaymentsOverview/PdfView.vue";
import { v4 as uuidv4 } from "uuid";

const RegionSpecificDocumentsModule = namespace("region-specific-documents/filter");
const MailboxModule = namespace("mailbox");

@Component({
  components: { PdfView, MailButton, FscSimpleCard, FscModal },
})
export default class ExamDocumentModal extends mixins() {
  public name = "ExamDocumentModal";

  @Prop({ type: String, required: true })
  public modalId!: string;

  @Prop({ type: String, required: true })
  public previewModalId!: string;

  @Prop({ type: Number, required: true })
  public examId!: number;

  @Prop({ type: Number, required: true })
  public documentTypeId!: number;

  @RegionSpecificDocumentsModule.Action("filter")
  public regionSpecificDocumentFilter!: (options: ICrudOptions) => Promise<void>;

  @RegionSpecificDocumentsModule.Getter("getData")
  public regionSpecificDocuments!: Array<IRegionSpecificDocument>;

  @RegionSpecificDocumentsModule.Getter("getIsLoading")
  public regionSpecificDocumentsLoading!: boolean;

  @MailboxModule.Action("setBlobFile")
  private setBlobFile: any;

  @MailboxModule.Action("setAttachExamDocument")
  public setAttachExamDocument: any;

  public documentPreviewSrc = "";
  public documentPreviewBlob = "";

  public documentPreviewSrcMimeType = "";
  public documentPreviewSrcLoading = false;

  public selectedRegionSpecificDocument: IRegionSpecificDocument | null = null;

  public get regionSpecificDocumentsOptions() {
    return (
      this.regionSpecificDocuments?.map((option: IRegionSpecificDocument) => {
        return {
          text: option?.name || "",
          value: option,
        };
      }) || []
    );
  }

  public fetchRegionSpecificDocumentOptions() {
    const { drivingSchoolId } = UserService.getTokenInfo();

    if (drivingSchoolId) {
      this.regionSpecificDocumentFilter({
        resource: "/region-specific-documents/filter",
        filter: {
          drivingSchoolId: drivingSchoolId,
          categoryId: RegionSpecificDocumentCategoriesEnum.CATEGORY_ID,
          documentTypeId: this.documentTypeId,
        },
      });
    }
  }

  public onModalShown() {
    this.fetchRegionSpecificDocumentOptions();
  }

  public showPreviewDocument() {
    this.$bvModal.show(this.previewModalId);
    let documentId = this.selectedRegionSpecificDocument?.id;

    if (!documentId) return;

    this.documentPreviewSrcLoading = true;

    getFile(
      {
        method: "get",
        url: `/region-specific-documents/generate-document?documentId=${documentId}&studentEducationId=${this.examId}`,
      },
      false,
      true,
      true
    )
      .then(({ blob, mimeType, data }) => {
        this.documentPreviewSrc = URL.createObjectURL(blob);
        this.documentPreviewBlob = data;
        this.documentPreviewSrcMimeType = mimeType;
      })
      .finally(() => {
        this.documentPreviewSrcLoading = false;
      });
  }

  public async onClickSendEmail(): Promise<void> {
    this.setAttachExamDocument(uuidv4());

    await this.setBlobFile({
      blob: this.documentPreviewBlob,
      mimeType: this.documentPreviewSrcMimeType,
    });

    this.$bvModal.hide(this.previewModalId);

    await this.$router.push({ name: "EmailClient" });
  }

  public get saveBtnDisabled() {
    return this.regionSpecificDocumentsOptions.length < 1;
  }
}
