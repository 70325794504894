
















import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";

const ProofOfTrainingModule = namespace("proof-of-training");

@Component({
  components: { AbortButton, FscSimpleCard, SaveButton },
})
export default class ProofOfTrainingDocumentPreview extends Vue {
  public name = "ProofOfTrainingDocumentPreview";

  @ProofOfTrainingModule.Action("generateProofOfTrainingPreview")
  public generateProofOfTrainingPreview: any;

  @ProofOfTrainingModule.Getter("getMimeType")
  public mimeType: any;

  @ProofOfTrainingModule.Getter("getDataItem")
  public blob: any;

  @ProofOfTrainingModule.Getter("getIsLoading")
  public isLoading: any;

  @Prop()
  public studentEducationId!: any;

  @Prop()
  public documentDate!: any;

  private source = "";

  public mounted(): void {
    if (this.studentEducationId) {
      const options = {
        studentEducationId: this.studentEducationId,
        issueDate: this.documentDate,
      };
      this.generateProofOfTrainingPreview(options);
    }
  }

  @Watch("blob")
  public onStudentDocumentChange(blob: any) {
    if (blob) {
      this.source =
        "data:" +
        this.mimeType +
        ";base64," +
        //@ts-ignore
        new Buffer(blob, "binary").toString("base64");
    }
  }

  @Watch("studentEducationId")
  public onItemChange(id: any): void {
    if (id) {
      const options = {
        studentEducationId: id,
        issueDate: this.documentDate,
      };
      this.generateProofOfTrainingPreview(options);
    }
  }

  protected ok(): void {
    this.$emit("ok");
  }

  protected cancel(): void {
    this.$emit("cancel");
  }
}
