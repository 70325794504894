import utilAxios from "@/utils/axios";
import axios, { AxiosRequestConfig } from "axios";

const createCancelableRequest = (id = "") => {
  let cancelTokenSource = axios.CancelToken.source();

  const cancelPreviousRequest = () => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel("Request canceled due to new request.");
    }
  };

  const makeRequest = async (config: AxiosRequestConfig) => {
    cancelPreviousRequest();

    cancelTokenSource = axios.CancelToken.source();

    return utilAxios({
      ...config,
      cancelToken: cancelTokenSource.token,
    });
  };

  return {
    makeRequest,
    cancelPreviousRequest,
  };
};

export default createCancelableRequest;
