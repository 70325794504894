





































import { Component, Prop, Vue } from "vue-property-decorator";
import { IPracticalExam } from "@/interfaces/Exam/IPracticalExam";

@Component({
  components: {},
})
export default class Info extends Vue {
  public name = "Info";

  @Prop()
  public exam!: IPracticalExam;

  protected get proctor(): string {
    return this.exam?.proctor || `&#8212;`;
  }

  private get date() {
    return this.exam?.date;
  }

  private get time() {
    return this.exam?.time;
  }

  private get testingOrganization() {
    return this.exam?.testingOrganization;
  }
}
