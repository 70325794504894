import { Component, Vue } from "vue-property-decorator";
import { AxiosError, AxiosResponse } from "axios";
import { IPracticalExam } from "@/interfaces/Exam/IPracticalExam";
import createCancelableRequest from "@/utils/axiosCancelable";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import { ulrStringify } from "@/utils/UrlUtils";
import i18n from "@/i18n";

@Component
export default class PracticalExamRequestMixin extends Vue {
  protected practicalExamCancelableRequest = createCancelableRequest(PracticalExamRequestMixin.name);

  protected practicalExam: IPracticalExam | null = null;
  private practicalExamLoadingQueue = 0;
  protected practicalExamSuccess = false;
  protected practicalExamError: Record<any, any> | null = null;
  protected get practicalExamLoading() {
    return this.practicalExamLoadingQueue > 0;
  }

  public async fetchPracticalExam(practicalExamId: number): Promise<void> {
    this.practicalExamLoadingQueue++;
    this.practicalExamSuccess = false;
    this.practicalExamError = null;

    return await this.practicalExamCancelableRequest
      .makeRequest({
        method: "get",
        url: `/practical-exams/${practicalExamId}`,
      })
      .then((response: AxiosResponse) => {
        this.practicalExam = response.data;
        this.practicalExamSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.practicalExamError = error.response?.data;
      })
      .finally(() => {
        this.practicalExamLoadingQueue--;
      });
  }

  protected archivePracticalExamCancelableRequest = createCancelableRequest(PracticalExamRequestMixin.name);
  private archivePracticalExamLoadingQueue = 0;
  public archivePracticalSuccess = false;
  public archivePracticalError = null;
  public get archivePracticalExamLoading() {
    return this.archivePracticalExamLoadingQueue > 0;
  }

  public async archivePracticalExam(option: ICrudOptions) {
    this.archivePracticalSuccess = false;
    this.archivePracticalError = null;
    this.archivePracticalExamLoadingQueue++;

    const args = ulrStringify(option.params);

    return await this.archivePracticalExamCancelableRequest
      .makeRequest({ method: "post", url: `/practical-exams/archive${args}` })
      .then((response: AxiosResponse) => {
        this.archivePracticalSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.archivePracticalError = error.response?.data;
      })
      .finally(() => {
        this.archivePracticalExamLoadingQueue--;
      });
  }

  protected allPracticalExamCancelableRequest = createCancelableRequest(PracticalExamRequestMixin.name);
  private allPracticalExamLoadingQueue = 0;
  public allPracticalSuccess = false;
  public allPracticalError = null;
  public allPracticalExams = [];
  public allPracticalExamsRows = 0;
  public get allPracticalExamsLoading() {
    return this.allPracticalExamLoadingQueue > 0;
  }

  public async getAllPracticalExam(filter: any) {
    this.allPracticalSuccess = false;
    this.allPracticalError = null;
    this.allPracticalExamLoadingQueue++;

    return await this.allPracticalExamCancelableRequest
      .makeRequest({ method: "post", url: `/practical-exams/filter`, data: filter })
      .then((response: AxiosResponse) => {
        this.allPracticalSuccess = true;
        this.allPracticalExams = response.data?.data || [];
        this.allPracticalExamsRows = response.data?.total || 0;
      })
      .catch((error: AxiosError) => {
        this.allPracticalError = error.response?.data;
      })
      .finally(() => {
        this.allPracticalExamLoadingQueue--;
      });
  }

  protected createPracticalExamCancelableRequest = createCancelableRequest(PracticalExamRequestMixin.name);
  private createPracticalExamLoadingQueue = 0;
  public createPracticalSuccess = false;
  public createPracticalError = null;
  public createdPracticalExamId = null;

  public get createPracticalExamLoading() {
    return this.createPracticalExamLoadingQueue > 0;
  }

  public async createPracticalExam(data: any) {
    this.createPracticalSuccess = false;
    this.createPracticalError = null;
    this.createPracticalExamLoadingQueue++;

    return await this.createPracticalExamCancelableRequest
      .makeRequest({ method: "post", url: `/practical-exams`, data })
      .then((response: AxiosResponse) => {
        this.createPracticalSuccess = true;
        this.createdPracticalExamId = response.data;
      })
      .catch((error: AxiosError) => {
        this.createPracticalError = error.response?.data;
      })
      .finally(() => {
        this.createPracticalExamLoadingQueue--;
      });
  }

  protected updatePracticalExamCancelableRequest = createCancelableRequest(PracticalExamRequestMixin.name);
  private updatePracticalExamLoadingQueue = 0;
  public updatePracticalSuccess = false;
  public updatePracticalError = null;

  public get updatePracticalExamLoading() {
    return this.updatePracticalExamLoadingQueue > 0;
  }

  public async updatePracticalExam(id: number, data: any) {
    this.updatePracticalSuccess = false;
    this.updatePracticalError = null;
    this.updatePracticalExamLoadingQueue++;

    return await this.updatePracticalExamCancelableRequest
      .makeRequest({ method: "put", url: `/practical-exams/${id}`, data })
      .then((response: AxiosResponse) => {
        this.updatePracticalSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.updatePracticalError = error.response?.data;
      })
      .finally(() => {
        this.updatePracticalExamLoadingQueue--;
      });
  }

  protected deletePracticalExamCancelableRequest = createCancelableRequest(PracticalExamRequestMixin.name);
  private deletePracticalExamLoadingQueue = 0;
  public deletePracticalSuccess = false;
  public deletePracticalError = null;

  public get deletePracticalExamLoading() {
    return this.deletePracticalExamLoadingQueue > 0;
  }

  public async deletePracticalExam(id: number) {
    this.deletePracticalSuccess = false;
    this.deletePracticalError = null;
    this.deletePracticalExamLoadingQueue++;

    return await this.deletePracticalExamCancelableRequest
      .makeRequest({ method: "delete", url: `/practical-exams/${id}` })
      .then((response: AxiosResponse) => {
        this.deletePracticalSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.deletePracticalError = error.response?.data;
      })
      .finally(() => {
        this.deletePracticalExamLoadingQueue--;
      });
  }

  protected readyPracticalExamCancelableRequest = createCancelableRequest(PracticalExamRequestMixin.name);
  private readyPracticalExamLoadingQueue = 0;
  public readyPracticalSuccess = false;
  public readyPracticalError = null;

  public get readyPracticalExamLoading() {
    return this.readyPracticalExamLoadingQueue > 0;
  }

  public async readyPracticalExam(id: number) {
    this.readyPracticalSuccess = false;
    this.readyPracticalError = null;
    this.readyPracticalExamLoadingQueue++;

    return await this.readyPracticalExamCancelableRequest
      .makeRequest({ method: "post", url: `/practical-exams/${id}/mark-as-ready` })
      .then((response: AxiosResponse) => {
        this.readyPracticalSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.readyPracticalError = error.response?.data;
      })
      .finally(() => {
        this.readyPracticalExamLoadingQueue--;
      });
  }

  protected portionsPracticalExamCancelableRequest = createCancelableRequest(PracticalExamRequestMixin.name);
  private portionsPracticalExamLoadingQueue = 0;
  public portionsPracticalSuccess = false;
  public portionsPracticalError = null;
  public portionsPracticalExam = [];

  public get portionsPracticalExamLoading() {
    return this.portionsPracticalExamLoadingQueue > 0;
  }

  public async getPortionsPracticalExam(licenseClass: string) {
    this.portionsPracticalSuccess = false;
    this.portionsPracticalError = null;
    this.portionsPracticalExamLoadingQueue++;

    return await this.portionsPracticalExamCancelableRequest
      .makeRequest({ method: "get", url: `/practical-exam-portions?licenseClass=${licenseClass}` })
      .then((response: AxiosResponse) => {
        this.portionsPracticalSuccess = true;
        this.portionsPracticalExam = response.data;
      })
      .catch((error: AxiosError) => {
        this.portionsPracticalError = error.response?.data;
      })
      .finally(() => {
        this.portionsPracticalExamLoadingQueue--;
      });
  }

  protected updateParticipantsPracticalExamCancelableRequest = createCancelableRequest(PracticalExamRequestMixin.name);
  private updateParticipantsPracticalExamLoadingQueue = 0;
  public updateParticipantsPracticalExamSuccess = false;
  public updateParticipantsPracticalExamError = null;

  public get updateParticipantsPracticalExamLoading() {
    return this.updateParticipantsPracticalExamLoadingQueue > 0;
  }

  public async updateParticipantsPracticalExam(data: any) {
    this.updateParticipantsPracticalExamSuccess = false;
    this.updateParticipantsPracticalExamError = null;
    this.updateParticipantsPracticalExamLoadingQueue++;

    return await this.updateParticipantsPracticalExamCancelableRequest
      .makeRequest({ method: "put", url: `/practical-exams/participants`, data })
      .then((response: AxiosResponse) => {
        this.updateParticipantsPracticalExamSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.updateParticipantsPracticalExamError = error.response?.data;
      })
      .finally(() => {
        this.updateParticipantsPracticalExamLoadingQueue--;
      });
  }

  protected removeParticipantPracticalExamCancelableRequest = createCancelableRequest(PracticalExamRequestMixin.name);
  private removeParticipantPracticalExamLoadingQueue = 0;
  public removeParticipantPracticalSuccess = false;
  public removeParticipantPracticalError = null;

  public get removeParticipantPracticalExamLoading() {
    return this.removeParticipantPracticalExamLoadingQueue > 0;
  }

  public async removeParticipantPracticalExam(examId: number) {
    this.removeParticipantPracticalSuccess = false;
    this.removeParticipantPracticalError = null;
    this.removeParticipantPracticalExamLoadingQueue++;

    return await this.removeParticipantPracticalExamCancelableRequest
      .makeRequest({ method: "delete", url: `/practical-exams/student-exam/${examId}` })
      .then((response: AxiosResponse) => {
        this.removeParticipantPracticalSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.removeParticipantPracticalError = error.response?.data;
      })
      .finally(() => {
        this.removeParticipantPracticalExamLoadingQueue--;
      });
  }

  // Update practical exam Status
  protected updatePracticalExamStatusCancelableRequest = createCancelableRequest(PracticalExamRequestMixin.name);
  private updatePracticalExamStatusLoadingQueue = 0;
  public updatePracticalExamStatusSuccess = false;
  public updatePracticalExamStatusError = null;

  public get updatePracticalExamStatusLoading() {
    return this.updatePracticalExamStatusLoadingQueue > 0;
  }

  public async updatePracticalExamStatus(practicalExamStatuses: Array<any>) {
    this.updatePracticalExamStatusSuccess = false;
    this.updatePracticalExamStatusError = null;
    this.updatePracticalExamStatusLoadingQueue++;

    return await this.updatePracticalExamStatusCancelableRequest
      .makeRequest({ method: "put", url: `/practical-exams/update-student-exam-statuses`, data: practicalExamStatuses })
      .then((response: AxiosResponse) => {
        this.updatePracticalExamStatusSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.updatePracticalExamStatusError = error.response?.data;
      })
      .finally(() => {
        this.updatePracticalExamStatusLoadingQueue--;
      });
  }

  // Book practical exam
  protected bookPracticalExamCancelableRequest = createCancelableRequest(PracticalExamRequestMixin.name);
  private bookPracticalExamLoadingQueue = 0;
  public bookPracticalExamSuccess = false;
  public bookPracticalExamError = null;

  public get bookPracticalExamLoading() {
    return this.bookPracticalExamLoadingQueue > 0;
  }

  public async bookPracticalExam(id: number) {
    this.bookPracticalExamSuccess = false;
    this.bookPracticalExamError = null;
    this.bookPracticalExamLoadingQueue++;

    return await this.bookPracticalExamCancelableRequest
      .makeRequest({ method: "post", url: `/practical-exams/${id}/book` })
      .then((response: AxiosResponse) => {
        this.bookPracticalExamSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.bookPracticalExamError = error.response?.data;
      })
      .finally(() => {
        this.bookPracticalExamLoadingQueue--;
      });
  }

  // Upload Protocol
  protected uploadProtocolPracticalExamCancelableRequest = createCancelableRequest(PracticalExamRequestMixin.name);
  private uploadProtocolPracticalExamLoadingQueue = 0;
  public uploadProtocolPracticalExamSuccess = false;
  public uploadProtocolPracticalExamError = null;

  public get uploadProtocolPracticalExamLoading() {
    return this.uploadProtocolPracticalExamLoadingQueue > 0;
  }

  public async uploadProtocolPracticalExam(data: FormData) {
    this.uploadProtocolPracticalExamSuccess = false;
    this.uploadProtocolPracticalExamError = null;
    this.uploadProtocolPracticalExamLoadingQueue++;

    return await this.uploadProtocolPracticalExamCancelableRequest
      .makeRequest({
        method: "post",
        url: `/practical-exams/upload-protocol`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data,
      })
      .then((response: AxiosResponse) => {
        this.uploadProtocolPracticalExamSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.uploadProtocolPracticalExamError = error.response?.data;
      })
      .finally(() => {
        this.uploadProtocolPracticalExamLoadingQueue--;
      });
  }

  // Education Status Info
  protected educationStatusInfoPracticalExamCancelableRequest = createCancelableRequest(PracticalExamRequestMixin.name);
  private educationStatusInfoPracticalExamLoadingQueue = 0;
  public educationStatusInfoPracticalExamSuccess = false;
  public educationStatusInfoPracticalExamError = null;
  public educationStatusInfoPracticalExamData = null;

  public get educationStatusInfoPracticalExamLoading() {
    return this.educationStatusInfoPracticalExamLoadingQueue > 0;
  }

  public async educationStatusInfoPracticalExam(studentEducationId: number) {
    this.educationStatusInfoPracticalExamSuccess = false;
    this.educationStatusInfoPracticalExamError = null;
    this.educationStatusInfoPracticalExamLoadingQueue++;

    return await this.educationStatusInfoPracticalExamCancelableRequest
      .makeRequest({
        method: "get",
        url: `/student-educations/${studentEducationId}/fulfilled-requirements-practical`,
      })
      .then((response: AxiosResponse) => {
        this.educationStatusInfoPracticalExamSuccess = true;
        const { requirementsToFulfillmentMap, studentEducationId } = response.data;

        const info: any = {
          studentEducationId,
          requirementsToFulfillmentMap: [],
        };
        if (Object.keys(requirementsToFulfillmentMap).length > 0) {
          Object.keys(requirementsToFulfillmentMap).forEach((key) => {
            info.requirementsToFulfillmentMap.push({
              name: key,
              status: requirementsToFulfillmentMap[key],
            });
          });
        } else {
          const message = i18n.t("general.no_data");
          info.requirementsToFulfillmentMap.push({ name: message, status: true });
        }
        this.educationStatusInfoPracticalExamData = info;
      })
      .catch((error: AxiosError) => {
        this.educationStatusInfoPracticalExamError = error.response?.data;
      })
      .finally(() => {
        this.educationStatusInfoPracticalExamLoadingQueue--;
      });
  }
}
