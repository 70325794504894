















import { Component, Inject, InjectReactive, Prop, Provide, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { IPracticalExam } from "@/interfaces/Exam/IPracticalExam";
import ExamList from "@/views/Exam/Components/ExamList.vue";
import { mixins } from "vue-class-component";
import ExamMixin from "@/mixins/ExamMixin";
import CustomSlider from "@/components/Slider/CustomSlider.vue";

const PracticalExamFilterModule = namespace("practical-exam/localFilter");

@Component({
  components: {
    ExamList,
    CustomSlider,
  },
})
export default class PracticalExamList extends mixins(ExamMixin) {
  public name = "PracticalExamList";

  private selectedExam?: IPracticalExam | null = {};

  public offset = 0;

  public currentPerPage = 7;

  @PracticalExamFilterModule.State("filter")
  public practicalExamLocalFilter: any;

  @PracticalExamFilterModule.Mutation("SET_LOCAL_FILTER")
  public setPracticalExamLocalFilter!: (payload: Record<any, any>) => void;

  @Prop({ type: String, default: () => "" })
  private filterPracticalExam!: string;

  @Prop({ type: Boolean, default: () => false })
  public archived!: any;

  @InjectReactive("deletePracticalExamLoading")
  private deletePracticalExamLoading!: boolean;

  @Inject("getAllPracticalExam")
  private getAllPracticalExam!: (filter: any) => Promise<void>;

  @InjectReactive("allPracticalExamsRows")
  private allPracticalExamsRows!: number;

  @InjectReactive("allPracticalExamsLoading")
  private allPracticalExamsLoading!: boolean;

  @InjectReactive("allPracticalExams")
  private allPracticalExams!: Array<any>;

  @Inject("readyPracticalExam")
  private readyPracticalExam!: (practicalExamId: number) => Promise<void>;

  @InjectReactive("readyPracticalExamLoading")
  private readyPracticalExamLoading!: boolean;

  @InjectReactive("archivePracticalExamLoading")
  private archivePracticalExamLoading!: boolean;

  private get exams() {
    return this.allPracticalExams;
  }

  private get totalRows() {
    return this.allPracticalExamsRows;
  }

  private get loading() {
    return this.allPracticalExamsLoading || this.readyPracticalExamLoading || this.deletePracticalExamLoading || this.archivePracticalExamLoading;
  }

  public loadExams(): void {
    this.onCurrentPageChange(this.offset);
  }

  public mounted() {
    if (this.practicalExamLocalFilter instanceof Object) {
      this.$emit("update:archived", this.practicalExamLocalFilter.archived);
    } else {
      this.onCurrentPageChange(this.offset);
    }
  }

  @Watch("archived")
  private async onArchivedChange(archived: any): Promise<void> {
    this.setPracticalExamLocalFilter({ archived });
    this.onCurrentPageChange(0);
  }

  private onClick(exam: IPracticalExam, parentSelected = false): void {
    this.selectedExam = exam;
    this.$emit("on-click", this.selectedExam, parentSelected);
  }

  private ondDblclick(exam: IPracticalExam): void {
    this.selectedExam = exam;
    this.$emit("on-dblclick", this.selectedExam);
  }

  protected async onReady(exam: any): Promise<void> {
    if (this.hasNotPermissionWrite) return;
    await this.readyPracticalExam(exam.id);
    exam.ready = !exam.ready;
  }

  public onCurrentPageChange(offset: any) {
    this.offset = offset;
    this.getAllPracticalExam({
      offset: offset,
      limit: 7,
      searchTerm: "",
      archived: this.archived,
    });
  }

  protected get filteredExams() {
    if (this.filterPracticalExam) {
      const filter = this.filterPracticalExam.toLowerCase();
      return this.exams.filter((courseAndTheoryLesson: any) => {
        return (
          courseAndTheoryLesson.proctor?.toLowerCase()?.indexOf(filter) > -1 ||
          courseAndTheoryLesson.notes?.toLowerCase()?.indexOf(filter) > -1 ||
          courseAndTheoryLesson.testingOrganization?.name?.toLowerCase()?.indexOf(filter) > -1 ||
          courseAndTheoryLesson.date?.toLowerCase()?.indexOf(filter) > -1
        );
      });
    }
    return this.exams;
  }
}
