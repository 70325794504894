





















































































import { Component, Inject, InjectReactive, Prop, ProvideReactive, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { mixins } from "vue-class-component";
import FscCard from "@/components/Card/FscCard.vue";
import Validation from "@/components/Validation.vue";
import Datepicker from "@/components/Datepicker.vue";
import TimePicker from "@/components/TimePicker.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import CreateButton from "@/components/Button/CreateButton.vue";
import CopyButton from "@/components/Button/CopyButton.vue";
import VerticalDivider from "@/components/VerticalDivider.vue";
import EditButton from "@/components/Button/EditButton.vue";
import RemoveButton from "@/components/Button/RemoveButton.vue";
import ArchiveButton from "@/components/Button/ArchiveButton.vue";
import FilterButton from "@/components/Button/FilterButton.vue";
import Actions from "@/components/Actions.vue";
import PrintButton from "@/components/Button/PrintButton.vue";
import MailButton from "@/components/Button/MailButton.vue";
import InfoButton from "@/components/Button/InfoButton.vue";
import Table from "@/components/Table.vue";
import FscTable from "@/components/Table/FscTable.vue";
import { removeObject } from "@/utils/ArrayUtils";
import { IStudentPracticalExam } from "@/interfaces/Exam/IStudentPracticalExam";
import Info from "@/views/Exam/PracticalExam/Participants/Info.vue";
import Students from "@/views/Exam/Components/Students.vue";
import ModalMixin from "@/mixins/ModalMixin";
import PracticalExamStudents from "@/views/Exam/PracticalExam/Participants/PracticalExamStudents.vue";
import _ from "lodash";
import { Portions } from "@/constants/Portions";
import { IStudentExamFormDTO } from "@/interfaces/Exam/IStudentExamFormDTO";
import DeleteModal from "@/components/Modal/DeleteModal.vue";
import FilterAndSearch from "@/components/FilterAndSearch.vue";
import { PropType } from "vue";
import { IInstructor } from "@/interfaces/IInstructor";
import TuvRequestMixin from "@/mixins/Request/TuvRequestMixin";
import moment from "moment";

const VehicleModule = namespace("vehicle");

@Component({
  components: {
    FilterAndSearch,
    PracticalExamStudents,
    Students,
    Info,
    FscTable,
    Table,
    InfoButton,
    MailButton,
    PrintButton,
    Actions,
    FilterButton,
    ArchiveButton,
    RemoveButton,
    EditButton,
    VerticalDivider,
    CopyButton,
    CreateButton,
    SaveButton,
    AbortButton,
    TimePicker,
    Datepicker,
    Validation,
    FscCard,
    DeleteModal,
  },
})
export default class PracticalExamStudentsSelect extends mixins(ModalMixin, TuvRequestMixin) {
  public name = "PracticalExamStudentsSelect";

  public deleteModalId = "delete-modal";

  protected studentFilter = "";

  private selectedExamStudent: IStudentPracticalExam | null = null;

  @Prop()
  private examId!: any;

  @Prop()
  public exam: any;

  @Prop({ type: Array as PropType<Array<IInstructor>>, default: () => [] })
  public instructors!: Array<IInstructor>;

  @Prop({ type: Boolean, default: () => false })
  public instructorsLoading!: boolean;

  @Prop({ type: Boolean, default: () => false })
  public loading!: boolean;

  // update participants
  @Inject("updateParticipantsPracticalExam")
  private updateParticipantsListAction!: (data: any) => Promise<void>;

  @InjectReactive({ from: "updateParticipantsPracticalExamSuccess", default: () => false })
  private updateParticipantsPracticalExamSuccess!: boolean;

  @InjectReactive({ from: "updateParticipantsPracticalExamLoading", default: () => false })
  private updateParticipantsPracticalExamLoading!: boolean;

  // remove participants
  @Inject("removeParticipantPracticalExam")
  private removeStudent!: (examId: number) => Promise<void>;

  @InjectReactive({ from: "removeParticipantPracticalSuccess", default: () => false })
  private removeStudentSuccess: any;

  @InjectReactive({ from: "removeParticipantPracticalExamLoading", default: () => false })
  private removeParticipantPracticalExamLoading!: boolean;

  // student educations
  @Inject("getStudentEducations")
  public studentEducationFindByStudent!: (studentId: number) => Promise<void>;

  @InjectReactive({ from: "studentEducations", default: () => [] })
  public studentEducations: any;

  @InjectReactive({ from: "studentEducationsLoading", default: () => false })
  public studentEducationsLoading!: boolean;

  // TODO: move to mixin
  @VehicleModule.Action("findAll")
  public vehicleFindAll: any;

  @VehicleModule.Getter("getDataList")
  public vehicles: any;

  // portions
  @Inject("getPracticalExamPortions")
  private findAllPortions!: (licenseClass: string) => Promise<void>;

  @InjectReactive({ from: "practicalExamPortions", default: () => [] })
  private portions!: Array<any>;

  @InjectReactive("portionsPracticalExamLoading")
  private portionsPracticalExamLoading!: boolean;

  // get practical exam
  @Inject("fetchPracticalExam")
  public fetchPracticalExam: any;

  @Inject("openParticipants")
  private openParticipants: any;

  @Inject("loadExams")
  private loadExams!: () => void;

  @InjectReactive("filterPracticalExam")
  public filterPracticalExam!: string;

  @InjectReactive("archivePracticalExamLoading")
  private archivePracticalExamLoading!: boolean;

  private examStudents: Array<IStudentPracticalExam> = [];

  public mounted() {
    this.vehicleFindAll({ resource: "vehicles?archived=false" });
  }

  private onRowClicked(student: IStudentPracticalExam): void {
    this.selectedExamStudent = student;
  }

  private removeStudentFromCurrentList(student: IStudentPracticalExam): void {
    removeObject(this.examStudents, student);
  }

  private async onSubmit(): Promise<void> {
    const studentExamFormDTOs: Array<IStudentExamFormDTO> = [];

    this.examStudents.forEach((exam: any) => {
      const examPortions = exam.portions.map((x: any) => {
        return {
          examPortion: {
            id: x.examPortionId,
          },
          examStatus: null,
        };
      });

      const builtExam = this.buildExam({
        studentId: exam.student.id,
        licenseClass: exam.licenseClass,
        instructorId: exam.instructor,
        examStatusId: 1,
        time: exam.time,
        examPortions: examPortions,
        vehicleIds: exam.vehicleIds,
      });

      studentExamFormDTOs.push(builtExam);
    });

    const exam = {
      practicalExamId: this.exam.id,
      studentExamFormDTOs: studentExamFormDTOs,
    };

    await this.updateParticipantsListAction(exam);

    if (this.updateParticipantsPracticalExamSuccess) {
      this.$toasted.success(String(this.$t("messages.save_success")));
      this.openParticipants();
      this.fetchPracticalExam(this.examId);
      this.loadExams();
    }
  }
  private showDeleteModal(): void {
    if (!this.selectedExamStudent) {
      return;
    } else if (this.selectedExamStudent && this.selectedExamStudent.unsaved) {
      this.removeStudentFromCurrentList(this.selectedExamStudent);
    } else {
      this.showModal(this.deleteModalId);
    }
  }

  private async onDelete(): Promise<void> {
    const examStudentId = this.selectedExamStudent?.id;
    if (examStudentId) {
      await this.removeStudent(examStudentId);
      if (this.removeStudentSuccess) {
        this.$toasted.success(String(this.$t("messages.delete_success")));
        await this.fetchPracticalExam(this.examId);
        this.loadExams();
      }
    }
  }

  private buildExam(exam: IStudentExamFormDTO): any {
    return {
      studentId: exam.studentId,
      licenseClass: exam.licenseClass.name ? exam.licenseClass.name : exam.licenseClass,
      time: exam.time,
      examStatusId: exam.examStatusId,
      examPortions: exam.examPortions,
      instructorId: exam.instructorId,
      vehicleIds: exam.vehicleIds,
    };
  }

  private onClose(): void {
    this.$emit("on-close");
  }

  protected async toggleSubLicenseClassExams(student: IStudentPracticalExam): Promise<void> {
    const findIndex = _.findIndex(this.examStudents, student);

    if (student.disabled) {
      return;
    } else if (student.portions?.length) {
      // Toggle visible portion
      student.visiblePortion = false;
      student.portions = [];
      return;
    }
    const licenseClass = student.licenseClass.id ? student.licenseClass.name : student.licenseClass;
    await this.findAllPortions(licenseClass);

    this.examStudents[findIndex].portions?.push({
      items: this.portions,
      time: null,
      instructor: null,
      examPortionId: null,
    });

    student.visiblePortion = !student.visiblePortion;
  }

  protected onLicenseClassChange(student: IStudentPracticalExam): void {
    if (!this.visibleSplitByLicenseClass(student)) {
      student.visiblePortion = false;
      student.portions = [];
    }

    if (student.instructor) {
      student.instructor = undefined;
    }
    const findInstructor = student.licenseClassInstructors.find((x: any) => x.mainLicenseClass === student.licenseClass?.name);

    if (findInstructor && findInstructor.instructors.length === 1) {
      student.instructor = findInstructor.instructors[0].id;
    }

    const lastUsedVehicle = student.lastUsedVehicles.find((lastUsedVehicle: any) => lastUsedVehicle.licenseClass == student.licenseClass?.name);

    const vehicleIds: any = [];

    if (lastUsedVehicle?.lastUsedVehicle) {
      vehicleIds.push(lastUsedVehicle.lastUsedVehicle.id);
    }

    student.vehicleIds = vehicleIds;
  }

  private async onStudentRowDoubleClicked(student: any): Promise<void> {
    if (!student.activeEducations || student.activeEducations.length === 0) {
      return;
    }
    let newStartTime = null;
    if (this.examStudents && this.examStudents.length > 0) {
      const lastExamInArray = this.examStudents[this.examStudents.length - 1];
      const lastExamStudentEducationId = lastExamInArray.licenseClass.id ? lastExamInArray.licenseClass.id : lastExamInArray.studentEducationId;
      const lastExamPortionId = lastExamInArray.portions?.length ? lastExamInArray.portions[0].examPortionId : null;
      const lastExamStartTime = lastExamInArray.time ? lastExamInArray.time : 0;

      if (lastExamPortionId) {
        await this.studentEducationDurationTimePortions(lastExamStudentEducationId, lastExamPortionId);
        newStartTime = moment(lastExamStartTime, "HH:mm").add(this.durationTime, "minutes");
      } else {
        await this.studentEducationDurationTime(lastExamStudentEducationId);
        newStartTime = moment(lastExamStartTime, "HH:mm").add(this.durationTime, "minutes");
      }
    }
    await this.studentEducationFindByStudent(student.id);
    const studentEducationId = this.studentEducations[0].id;

    const licenseClassInstructors = this.studentEducations.map((education: any) => ({
      mainLicenseClass: education.mainLicenseClass,
      instructors: education.instructors,
    }));

    const licenseClasses = this.studentEducations.map((education: any) => ({
      id: education.id,
      name: education.mainLicenseClass,
    }));

    const instructorByLicenseClass = licenseClassInstructors.find((x: any) => x.mainLicenseClass === student.activeEducations[0]);

    let instructorId = null;
    if (instructorByLicenseClass && instructorByLicenseClass.instructors.length === 1) {
      instructorId = instructorByLicenseClass.instructors[0].id;
    }

    const lastUsedVehicles = this.studentEducations.map((education: any) => {
      return {
        licenseClass: education.mainLicenseClass,
        lastUsedVehicle: education?.lastUsedVehicle,
      };
    });

    const lastUsedVehicle = lastUsedVehicles.find((lastUsedVehicle: any) => lastUsedVehicle.licenseClass == licenseClasses[0]);

    const vehicleIds: any = [];

    if (lastUsedVehicle?.lastUsedVehicle) {
      vehicleIds.push(lastUsedVehicle.lastUsedVehicle.id);
    }

    const examStudent: IStudentPracticalExam = {
      id: student.id,
      student: student,
      time: newStartTime ? newStartTime.format("HH:mm") : this.exam.time,
      licenseClass: licenseClasses[0],
      licenseClasses: licenseClasses,
      visiblePortion: false,
      disabled: false,
      portions: [],
      licenseClassInstructors: licenseClassInstructors,
      instructor: instructorId,
      vehicleIds: vehicleIds,
      unsaved: true,
      lastUsedVehicles: lastUsedVehicles,
      studentEducationId: studentEducationId,
    };
    this.examStudents.push(examStudent);
  }

  @Watch("exam", { deep: true, immediate: true })
  protected async initExam(exam: any): Promise<void> {
    this.examStudents = [];
    if (!exam.studentPracticalExams || exam.id !== this.examId) return;

    let examStudents: Array<any> = [];
    for (const practicalExam of exam.studentPracticalExams) {
      let examStudent = {
        id: practicalExam.id,
        student: practicalExam.student,
        time: practicalExam.time,
        licenseClass: practicalExam.licenseClass,
        licenseClasses: [practicalExam.licenseClass],
        instructor: this.getInstructor(practicalExam.instructor?.id, practicalExam.studentEducationInstructors),
        studentEducationId: practicalExam.studentEducationId,
        portions: [],
        visiblePortion: false,
        disabled: true,
        licenseClassInstructors: [
          {
            mainLicenseClass: practicalExam.licenseClass,
            instructors: practicalExam.studentEducationInstructors,
          },
        ],
        vehicleIds: _.map(practicalExam.vehicles, (v: any) => v.id),
      };

      await this.findAllPortions(practicalExam.licenseClass);

      const portions: Array<any> = [];
      if (practicalExam.examPortions) {
        for (const portion of practicalExam.examPortions) {
          portions.push({
            items: this.portions,
            examPortionId: portion.examPortion.id,
          });
        }
        if (portions.length > 0) {
          examStudent.visiblePortion = true;
        }
      }

      // @ts-ignore
      examStudent.portions = portions;
      examStudents.push(examStudent);
    }

    this.examStudents = examStudents;
  }

  protected getInstructor(instructorId: number, instructors: Array<any>): any {
    const hasInstructor = instructors && instructors.length === 1;
    const defaultInstructor = hasInstructor ? instructors[0].id : null;
    return instructorId || defaultInstructor;
  }

  protected get portionTypes(): Array<any> {
    return Portions;
  }

  protected visibleSplitByLicenseClass(item: any): boolean {
    const licenseClass = item.licenseClass.id ? item.licenseClass.name : item.licenseClass;
    return this.portionTypes.includes(licenseClass);
  }

  private get isLoading() {
    return (
      this.loading ||
      this.studentEducationsLoading ||
      this.updateParticipantsPracticalExamLoading ||
      this.portionsPracticalExamLoading ||
      this.removeParticipantPracticalExamLoading ||
      this.archivePracticalExamLoading
    );
  }
}
