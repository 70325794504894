















import { Component, Prop } from "vue-property-decorator";
import { BModal } from "bootstrap-vue/src/components/modal";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import ModalMixin from "@/mixins/ModalMixin";
import { mixins } from "vue-class-component";

@Component({
  components: { BModal, SaveButton, AbortButton },
})
export default class DeleteModal extends mixins(ModalMixin) {
  public name = "DeleteModal";
  @Prop()
  public title!: string;

  @Prop()
  public question!: string;

  @Prop({ type: Function, default: () => null })
  public deleteAction!: any;

  @Prop({ type: String, default: () => "delete-modal-id" })
  public modalId!: string;

  private ok(): void {
    this.deleteAction();
  }
}
