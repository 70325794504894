import { Component, Vue } from "vue-property-decorator";
import { AxiosError, AxiosResponse } from "axios";
import axios from "@/utils/axios";
import { IInstructor } from "@/interfaces/IInstructor";

const URL = "instructor";

@Component
export default class InstructorRequestMixin extends Vue {
  public instructors: Array<IInstructor> = [];
  public instructorsTotal = 0;
  public instructorsLoading = false;
  public instructorsSuccess = false;
  public instructorsError: Record<any, any> | null = null;

  public async filterInstructor(filter: any = {}): Promise<void> {
    this.instructorsLoading = true;
    this.instructorsSuccess = false;
    this.instructorsError = null;
    return await axios
      .post(`${URL}/filter`, filter)
      .then((response: AxiosResponse) => {
        this.instructors = response.data.data;
        this.instructorsTotal = response.data.total;
        this.instructorsSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.instructorsError = error.response?.data;
      })
      .finally(() => {
        this.instructorsLoading = false;
      });
  }
}
