








import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class FscTable extends Vue {
  public name = "FscTable";

  @Prop({ default: () => [] })
  public items!: Array<any>;

  @Prop({ default: () => [] })
  public fields!: Array<any>;

  @Prop({ default: () => 1 })
  public total!: number;

  public onRowClicked(context: any): void {
    this.$emit("row-clicked", context);
  }

  public onRowDblClicked(context: any): void {
    this.$emit("row-dblclicked", context);
  }
}
