








import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class PdfView extends Vue {
  public name = "PdfView";

  @Prop()
  public file!: any;
}
